<template>
  <div class="container">
    <!-- <navigation msg="我的企业"></navigation> -->
    <div class="header">
       <van-icon  @click="goback()" size=".35rem" name="arrow-left"/>
       <div class="title1">我的企业</div>
    </div>
    <div class="set" @click="isShow">入离职设置</div>

    <van-action-sheet v-model="show"  cancel-text="取消" :actions="actions" @select="onSelect" />

     <!-- 卡片 -->
    <div style="background-color:#fff">
      <!-- 图片 -->
       <img v-if="companyDetail.company_img" style="width: 6.57rem;height: 3.25rem;margin:0.3rem 0.46rem 0" :src="companyDetail.company_img" alt="">
       <img v-else style="width: 6.57rem;height: 3.25rem;margin:0.3rem 0.46rem 0" src="../../assets/companyImg.png" alt="">
       <!-- title -->
       <div class="title">{{companyDetail.company == '' ? "暂无" : companyDetail.company}}</div>
       <!-- info -->
       <div class="flex">
         <div class="info">{{companyDetail.province == '' ? "" : companyDetail.province}} | 入职企业</div>
       </div>
       <div @click="goViewInfo" style="border-top:0.01rem solid #ddd;padding-top:0.25rem;" class="flex">
         <div>查看个人信息</div>
         <van-icon size=".3rem" name="arrow" />
       </div>
    </div>
    <!-- 企业简介 -->
    <div class="companyInfo">
      <div class="companyTitle">
        <span >企业简介</span>
        <img class="iconStyle" src="../../assets/companyInfo.png" alt="">
      </div>
      <div class="txt">{{companyDetail.company_desc == '' ? '暂无':companyDetail.company_desc}}</div>
    </div>
    <!-- 企业地址 -->
    <div class="companyInfo">
      <div class="companyTitle">
        <span >企业地址</span>
        <img class="iconStyle" src="../../assets/address.png" alt="">
      </div>
      <div class="txt">{{companyDetail.address == '' ? "暂无" : companyDetail.address}}</div>
    </div>
    <!-- 企业信息 -->
    <div class="infos">
      <div class="flex1">
        <div class="companyTitle">
          <span >企业信息</span>
          <img class="iconStyle" src="../../assets/info.png" alt="">
        </div>
        <div class="more"  @click="goCompanyMoreDetail">查看更多企业信息 ></div>
      </div>
      <div class="flex1 setheight">
        <div class="name">公司名称</div>
        <div class="detail">{{companyDetail.company == "" ? "暂无" : companyDetail.company}}</div>
      </div>
      <div class="flex1 setheight">
        <div class="name">注册资金</div>
        <div class="detail">{{companyDetail.reg_cap == '' ? "暂无" : companyDetail.reg_cap}}</div>
      </div>
    </div>

    <!-- 企业评价 -->
    <div class="companyInfo">
      <div class="flex1">
        <div class="companyTitle">
          <span>企业评价</span> 
          <img class="iconStyle" src="../../assets/companyInfo.png" alt="">
        </div>
        <div class="more" @click="gomoreComment">查看更多评论信息 ></div>
      </div>
    </div>

    <div v-if="list.length>0" class="companyList">
     <div class="item" @click="goDetail(item.id)" v-for="(item,index) in list" :key="index">
        <div style="display:flex;">
          <div style="width:1rem;height:1rem;margin-right:0.15rem;">
              <img style="width:1rem;height:1rem;border-radius:50%;" src="../../assets/user_1.jpg" alt="">
          </div>
          <div>
            <div class="title3">对{{item.staff_name}}的评价</div>
            <div class="content">{{item.is_open == 2 ? '******************' : item.content}}</div>
            <div v-if="item.is_open ==2">{{item.type == 1 ? "企业已屏蔽" : "员工已屏蔽"}}</div>
          </div>
        </div>
        <van-icon style="float:right" size=".35rem" name="arrow"/>
     </div>
    </div>

    <div class="nomsg" v-else>
      <img src="../../assets/recomment1.png" alt="">
      <div>- 您还没有任何评价 -</div>
    </div>

  </div>
</template>
<script>
import { Toast } from 'vant'
// import navigation from "../../components/navigation/index"
export default {
  // components:{
  //   navigation
  // },
  data(){
    return {
      companyDetail:{},
      list:[],
      show:false,
      actions:[{ name: '离职' ,status:3}],
    }
  },
  mounted(){
    let companyId = sessionStorage.getItem("companyId")
    this.$http.post("/user/v1/personal_info/companyDetail",
    {reqType:"companyDetail",company_id:companyId})
    .then((res)=>{
      
      let response = JSON.parse(res.data)
      console.log(response)
      if(response.code == 0){
         this.companyDetail = response.data.companyInfo
       this.list = response.data.comment
      }else{
        Toast.fail(response.msg)
      }
     
      // console.log(response)
    })
    .catch((e)=>{console.log(e)})
  },
  methods:{
    goback(){
      this.$router.push("./companyList")
    },
    onSelect(value){
      // console.log(value)
      this.show = false
      this.$http.post("/user/v1/personal_info/quit",{
        reqType:"quit",
        status:value.status,
        companyId:sessionStorage.getItem("companyId")
      })
      .then((res)=>{
          res = JSON.parse(res.data)
          if(res.code == 0){
            Toast.success("离职成功")

          }else{
            Toast.fail(res.msg)
          }
          
        })
      .catch((e)=>{console.log(e)})
    },
    isShow(){
      this.show = true
    },
    goViewInfo(){
      let companyId =  sessionStorage.getItem("companyId")
      this.$router.push({path:"./addInfo",query:{id:companyId}})
    },
    goCompanyMoreDetail(){
      let companyId =  sessionStorage.getItem("companyId")
      this.$router.push({path:"./companyMoreDetail",query:{id:companyId}})
    },
    gomoreComment(){
       let companyId = sessionStorage.getItem("companyId")
      this.$router.push({path:"./moreComment",query:{id:companyId}})
    },
    goDetail(id){
      this.$router.push({path:"./commentDetail",query:{id:id,type:1}})
    }
  },

}
</script>
<style scoped>
.set{
  font-size: 0.27rem;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #F55814;
position:absolute;
top:0.3rem;
right:0.2rem;
}
.item{
  display:flex;
  align-items: center;
  justify-content: space-between;
  padding:0.4rem 0.4rem;
  background:#fff;
}
.infos{
background-color: #fff;
margin-top:0.16rem;
padding:0.38rem 0.62rem 0 0.62rem;
}
.flex1{
  display:flex;
  justify-content: space-between;
  align-items: center;
}
.txt{
  font-size: 0.24rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #555555;
margin-top:0.22rem;
}
.companyTitle span{
  font-size: 0.38rem;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #222222;
margin-right:0.2rem;
}
.iconStyle{
  width: 0.3rem;
height: 0.31rem;
}
.flex{
  display:flex;
  justify-content: space-between;
  align-content: center;
  margin:0.1rem 0.62rem 0 0.62rem;
  
  padding-bottom:0.2rem;
}
 .title{
   font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  margin-top:0.25rem ;
  margin:0.2rem 0.62rem;
 }
 .info{
   font-size: 0.24rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #555555;
 }
 .companyInfo{
/* height: 1.9rem; */
background: #FFFFFF;
margin-top:0.14rem;
padding:0.38rem 0.62rem 0.35rem 0.62rem;
 }
 .more{
   font-size: 0.24rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #F55814;
 }
 .setheight{
    height: 1.13rem; 
 }
.name{
  font-size: 0.3rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #333333;
}
.detail{
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #555555;
}
.container{
  position: fixed;
  height:100%;
  width:100%;
  background-color: #fff;
  top: 0;
  bottom:0;
  overflow-y:scroll;
  overflow-x:hidden;
}
.title1{
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #777777;
  margin-top:0.1rem;
}
.content1{
  margin-top:0.2rem;
  font-size: 0.24rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #555555;
}
.title3{
  font-size: 0.24rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #777777;
}
.content{
  font-size: 0.24rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #555555;
margin-top:0.2rem;
}
.nomsg{
  text-align: center;
  margin:1rem 0; 

}
.nomsg img{
  width:2rem;
  height:1.8rem

}
.nomsg div{
  font-size: 0.24rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #6F6F6F;
margin-top:0.2rem;
}
.header{
  height:1rem;
 display:flex;
 align-items: center;
 
  background-color: #fff;
  padding:0 0.2rem;
 
}
.title1{
   margin-left:2.5rem;
  font-size: 0.34rem;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #333333;
}
</style>